<template>
  <section class="TestPage">
    <Header :broadcast="broadcast" :currentBrand="currentBrand" />
    <b-container>
      <h1 class="my-5" v-if="broadcast.name">{{ broadcast.name }}</h1>
      <h2 class="my-5">Livestream Testpagina</h2>
      <b-row class="mb-3">
        <b-col cols="12" lg="6" id="videoPlayerWrapper" class="mb-3 justify-content-center">
          <VideoPlayer
            :broadcast="testBroadcast"
            :playerId="playerId"
            :currentBrand="currentBrand"
            :loop=true
            @show-click-to-start-message="
              showClickToStartMessage = arguments[0]
            "
            @playing="playing = arguments[0]"
          />
          <p v-if="showClickToStartMessage" class="p-3 mt-3 bg-danger text-white">
            Klik op de afbeelding om te beginnen met afspelen.
          </p>
          <p class="mt-3" v-if="broadcast.name">
            <router-link
              class="btn btn-sm btn-outline-dark theme--bg"
              :to="{name: 'PlayerPage', params: this.$route.params}"
              >Ga terug naar de uitzending</router-link
            >
          </p>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3 text-left">
          <p>
            U kunt de livestream bekijken op bijna elk apparaat zolang u over
            een (stabiele) internet verbinding beschikt.
          </p>
          <h3>Aanbeveling</h3>
          <p>
            De meest optimale manier om een livestream te volgen is vanuit een
            desktop of laptop computer met de Google Chrome browser.
          </p>
          <h3>Ondersteunde apparaten en browsers</h3>
          <ul>
            <li>Google Chrome</li>
            <li>Mozilla Firefox</li>
            <li>Mac Safari</li>
            <li>Microsoft Edge</li>
            <li>Android 4.2 en nieuwer</li>
            <li>Apple iOS (Safari 10 of nieuwer, Google Chrome)</li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import currentBrandMixin from '../mixins/currentBrandMixin';
import domainChecker from '../mixins/domainChecker';
import broadcastHelperMixin from '../mixins/broadcastHelperMixin';
import Broadcast from '../models/Broadcast';
import Header from '../components/Header.vue';
import hero from '../../public/hero.png';

const testBroadcast = {
  playbackUrl: 'https://stream.mux.com/xS44emfk01E8TLTsSl00uLT9QqSUyux4c3.m3u8?redundant_streams=true',
  ulid: 'TestStream',
  state: 'pseudoLive',
  name: 'Livestream Testpagina',
  thumbnailUrl: hero,
};

export default {
  mixins: [currentBrandMixin, domainChecker, broadcastHelperMixin],
  components: {
    Header,

    VideoPlayer: () => import(
      /* webpackPrefetch: true, webpackChunkName: "VideoPlayer" */ '../components/VideoPlayer.vue'
    ),
  },
  data() {
    const { host } = window.location;
    return {
      host,
      loading: true,
      errored: false,
      errors: null,
      shareId: '',
      disabled: true,
      landingPageUrl: false,
      invalidCode: false,
      gone: false,
      data: null,
      broadcast: null,
      testBroadcast,
      playerId: this.$cookies.get('playerId'),
      showClickToStartMessage: true,
    };
  },
  props: {
    txtTitle: {
      type: String,
      required: false,
      default: 'Voer hier uw code in:',
    },
    txtSubmit: { type: String, required: false, default: 'Verzend' },
    txtUnlocked: {
      type: String,
      required: false,
      default: 'Code is correct.',
    },
    txtLink: {
      type: String,
      required: false,
      default: 'Bekijk uitzending',
    },
    txtError: {
      type: String,
      required: false,
      default:
        'De ingevoerde code is niet correct. <br/>Probeer een andere code.',
    },
    txtGone: {
      type: String,
      required: false,
      default: 'De ingevoerde code is niet meer geldig.',
    },
    txtConnectionError: {
      type: String,
      required: false,
      default:
        'Contact met de server is mislukt. <br/>Probeer het later opnieuw.',
    },
    mode: { type: String, required: false },
  },
  mounted() {
    document.title = 'Livestream Testpagina';
    this.retrieveBroadcast(); // initial call
  },
  methods: {
    retrieveBroadcast() {
      const { shareId } = this.$route.params;
      if (shareId && !this.requestPending) {
        this.requestPending = true;
        Broadcast.where({ shareId: this.discoverShareId() })
          .all()
          .then((response) => {
            // eslint-disable-next-line prefer-destructuring
            this.broadcast = { ...response.data[0] };
            this.errored = false;
            this.error = null;
            if (this.state === 'notFound' || this.state === 'gone' || this.state === 'unknown') {
              this.state = this.broadcast.state || 'inactive';
            }
          })
          .catch((error) => {
            if (error.response.status === 410) {
              this.state = 'gone';
            } else if (error.response.status === 404) {
              this.state = 'notFound';
            } else {
              this.state = 'unknown';
              // eslint-disable-next-line no-console
              console.error(error);
            }
            this.error = error;
          })
          .finally(() => {
            this.loading = false;
            this.requestPending = false;
          });
      }
    },
  },
  watch: {
    'broadcast.name': function broadcastState(newVal) {
      document.title = `Livestream Testpagina - ${newVal}`;
    },
  },
};
</script>
